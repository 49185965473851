
import React,{ Component, Fragment } from "react";
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { spin, stop } from '../../store/spinner'; 
import { browserHistory } from "react-router";
import { setCheckoutPlanParams,updateCheckoutPlanParams,clearCheckoutPlanParams } from '../../store/checkoutPlanParams';
import { setCheckoutMealPlan,clearCheckoutMealPlan } from '../../store/checkoutMealPlan';
import OrderSuccess from '../../assets/ordersuccess.png';

class CheckoutAuthorised extends Component{

    constructor(props) {
        super(props);
        
        const { checkoutPlanParams } = this.props
        const { action } =this.props.params;
       
        this.state={
           isPlaced:false,
           action
        }
    }
    componentDidMount(){
     
      const { socket } = this.props;
      if(socket.connected){
          this.initializeData();
      }else{
          socket.on('connect', (data) => {
              this.initializeData();
          });
      } 
    }
    initializeData =() =>{
      const {user } = this.props;
      // const { id:checkoutId } = this.props.checkoutPlanParams || {};
      // console.log(checkoutId);
      // console.log('action');
      // console.log(this.state.action); 
      // if(!checkoutId){ 
          this.props.spin(); 
          this.getPending(user.user_id);
      // }
    }
    componentWillMount() {
      this.props.socket.on('mobile_checkout', this.listener);
    }
    componentWillUnmount() {
    this.props.socket.removeListener('mobile_checkout', this.listener);
    }
    listener = ({ type, data, message }) => {
      this.props.stop();
      // console.log(type);
      switch (type) {
        case 'checkTelrStatusOk':
        if(!data.status){
            browserHistory.push('/checkout');
        }else{
            this.placenewOrder('telr');
        } 
        break;
        case 'placeneworderOk':
            this.props.clearCheckoutMealPlan();
            this.props.clearCheckoutPlanParams();
            this.setState({isPlaced:true});
          break;
        case 'getPendingOk':
              const { action } = this.state;
              console.log('action');
              console.log(action); 
              const { setCheckoutPlanParams, setCheckoutMealPlan } = this.props;
              if(data && data.mealPlan !=undefined ){
                setCheckoutPlanParams(data.planParams);
                setCheckoutMealPlan(data.mealPlan);
                if(action=='paylater'){
                  this.placenewOrder('Not Paid');
                }else if(action=='telr'){
                  this.checkTelrSession(data.planParams.id);
                }else{
                  browserHistory.push('/');
                }
                
              }else{
                browserHistory.push('/');
              }
        break;

        case 'setErr':
          // console.log(data);
          console.log(message);
        break;
      }
    }
    placenewOrder =(method)=>{
        const { checkoutPlanParams } = this.props;
        this.props.spin();
        this.props.socket.emit('mobile_checkout', { type: 'placeneworder',   data: {
          checkoutId:checkoutPlanParams.id,
          action:'newPlan',
          paymentMethod:method
        }});
      }
     getPending = user_id => this.props.socket.emit('mobile_checkout', { type: 'getPending', data: { user_id } });
     checkTelrSession = (checkoutId) =>{
        this.props.spin();
        this.props.socket.emit('mobile_checkout', { type: 'checkTelrStatus', data: { checkoutId } });
    }
    renderSuccess=(text)=>{
      return <div style={{flex: 1 }} > 
      <div style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
          <h5 style={{textAlign:'center',marginBottom:10,color:'#001f3d',marginTop:100,paddingHorizontal:20,fontSize:24}}>Welcome to your wellness journey!</h5>
          <p style={{textAlign:'center',marginBottom:20,color:'#12bdc7',fontWeight:'bold',fontSize:20}}>{text}</p>
          <div className="btn-con" style={{display:'inline-block'}}><div className='button-small transition continue' onClick={() => { browserHistory.push('/'); }}><span>Continue</span></div></div>
      </div>
      {/* <img src={OrderSuccess} /> */}
    </div>;
    }
    render(){
        const { isPlaced,action } = this.state;
       
        return <Fragment>
        <div className="main-holder checkout-process-con wrap-progress-page" >
        <div className="container checkout-process">
                    <div className="row">
                    <div className='col-12'>
                       <div className="success-container text-center">
                       {!isPlaced &&   <h5 style={{textAlign:'center',marginBottom:10,color:'#001f3d',marginTop:100,fontSize:20}}>Please wait, order is being processed</h5>}
                       {isPlaced && this.renderSuccess('Order Placed Successfully!!!')}
                       </div>
                    </div>
                </div>
                </div>
          </div>
    </Fragment>;
    }
}

CheckoutAuthorised.propTypes = {
    checkoutPlanParams: PropTypes.object.isRequired,
    checkoutMealPlan: PropTypes.object.isRequired,
    setCheckoutMealPlan: PropTypes.func.isRequired,
    setCheckoutPlanParams: PropTypes.func.isRequired,
    dataArray: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    checkoutPlanParams: state.checkoutPlanParams,
    dataArray: state.dataArray,
    user:state.user,
    checkoutMealPlan: state.checkoutMealPlan,
    customPlans: state.customPlans
});

const bindAction = dispatch => ({
    setModal: obj => dispatch(setModal(obj)),
    setCheckoutMealPlan: plan => dispatch(setCheckoutMealPlan(plan)),
    setCheckoutPlanParams: obj => dispatch(setCheckoutPlanParams(obj)),
    updateCheckoutPlanParams: obj => dispatch(updateCheckoutPlanParams(obj)),
    spin: () => dispatch(spin()),
    stop: () => dispatch(stop()),
    clearCheckoutMealPlan: () => dispatch(clearCheckoutMealPlan()),
    clearCheckoutPlanParams: () => dispatch(clearCheckoutPlanParams()),
    clearModal: obj => dispatch(clearModal(obj))
});
  
export default socketConnect(connect(mapStateToProps, bindAction)(CheckoutAuthorised));