import React,{ Component, Fragment } from "react";
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getArrays } from 'lib';
import PropTypes from 'prop-types';
import moment from 'moment';
import { setCheckoutPlanParams } from '../../store/checkoutPlanParams';
import { setCheckoutMealPlan,updateCheckoutMealPlan } from '../../store/checkoutMealPlan';
import { spin, stop } from '../../store/spinner'; 
import { setModal, clearModal } from '../../store/modal';
import Tabs from '../../components/Checkout/Tabs';
import BottomNav from '../../components/Checkout/BottomNav';
import Days from '../../components/Checkout/Days';
import Notpaidnotification from '../../components/Checkout/Notpaidnotification';
import { browserHistory } from "react-router";
import PaperButton from 'react-paper-button';

class CheckoutStep4 extends Component{

    constructor(props) {
        super(props);
        const { checkoutMealPlan, checkoutPlanParams } =this.props;
        const { id:checkoutId } = checkoutPlanParams;
        console.log(checkoutPlanParams);
        if(checkoutPlanParams==undefined || checkoutId==undefined) browserHistory.push('/checkout');
        const today = moment().startOf('day');
        const startDate = (checkoutMealPlan)?Object.keys(checkoutMealPlan)[0]:'';
        const date = moment.unix(startDate); 
        const buffered = today.unix()> startDate || date.isBetween(today, today.clone().add(3, 'day'), '[)');
        if(checkoutId && buffered) browserHistory.push('/checkout/delivery-dates');
        this.state={
            activeStamp:(checkoutMealPlan)?Object.keys(checkoutMealPlan)[0]:'',
            testing:'main'
        }
       
    }
    componentDidMount(){
      const { socket } = this.props;
      if(socket.connected){
          this.initializeData();
      }else{
          socket.on('connect', (data) => {
              this.initializeData();
          });
      } 
    }
    initializeData =() =>{
        const { socket, dataArray,checkoutPlanParams } = this.props;
        const types = ['productsList','dietList','typeList'].filter(el => !(el in dataArray));    
        types.length && getArrays(socket, types);
        const { diet_id,mealTypes } = checkoutPlanParams;
        this.getDefaultDishes(diet_id,mealTypes);
    }

    componentWillMount() {
        this.props.socket.on('mobile_checkout', this.listener);
    }
    componentWillUnmount() {
      this.props.socket.removeListener('mobile_checkout', this.listener);
    }
    listener = ({ type, data, message }) => {
        this.props.stop();
        console.log(this.state.testing);
        const { checkoutMealPlan,setCheckoutPlanParams, setCheckoutMealPlan,updateCheckoutMealPlan } = this.props;
        switch (type) {
          case 'getPendingOk':
            setIsSkip(true);
            // this.setState({planParams:data.planParams,mealPlan:data.mealPlan});
            if(data && data.mealPlan !=undefined ){
              setCheckoutPlanParams(data.planParams);
              setCheckoutMealPlan(data.mealPlan);
              if(Object.keys(data.mealPlan).length>0){
                this.setState({activeStamp:Object.keys(data.mealPlan)[0]});
              }
            }
          break;
          case 'setMealPlanOk':
            //console.log(data);
            const {  checkoutMealPlanUpdate } = data;
            if(checkoutMealPlanUpdate){
                Object.keys(checkoutMealPlanUpdate).map((currentDate) => {
                  Object.keys(checkoutMealPlanUpdate[currentDate]).map((currentType) => {
                    setCheckoutMealPlan({ ...checkoutMealPlan, [currentDate]: { ...checkoutMealPlan[currentDate], [currentType]: checkoutMealPlanUpdate[currentDate][currentType]} });                    
                  });
                });
            }
            //setIsMenuUpdated(false);
            // Alert.alert(
            //   'Your menu is updated.',
            // )
            // if(isSave){
              // navigation.navigate('BuildMenuSummary');
            // }
          break;
          case 'skipTheDayOk':
            delete checkoutMealPlan[data.skipDate];
            setCheckoutMealPlan(checkoutMealPlan);
            updateCheckoutMealPlan(data.newDate,data.newMealplan);
            this.setState({activeStamp:data.newDate});
            break;
            case 'removeOk':
              setCheckoutPlanParams({});
              setCheckoutMealPlan({});
             browserHistory.push('/checkout');
            break;
          case 'setMealPlanErr':
            console.log(data);
            console.log(message);
          break;
          case 'viewmenupdfOk':
          this.props.stop();
          window.open(data.url, '_blank');
          // this.props.setModal({ headLine: '  ', message: (
          //   <div className='viewmenupdf'>
          //     <p align="center">
          //         <PaperButton className='button-regular next transition'
          //           onClick={ (e) => {
                      
          //           }}
          //         >
          //         <span className="">View my menu in PDF</span>
          //         </PaperButton>
          //     </p>
          //   </div>)}); 
        break;
        }
      }
    getPending = user_id => this.props.socket.emit('mobile_checkout', { type: 'getPending', data: { user_id } });
    
   
    skipTheDay = (date, new_date) => {
        const { checkoutPlanParams } = this.props;
        var planId =  checkoutPlanParams.id; 
        if(planId){
          this.props.spin();
          this.props.socket.emit('mobile_checkout', { type: 'skipTheDay', data: { skip_date: date, new_date: new_date, planId: planId }});
        }
      }
       removeCheckout = () => {
        const { checkoutPlanParams } = this.props;
        var planId =  checkoutPlanParams.id; 
        if(planId){
          this.props.spin();
          this.props.socket.emit('mobile_checkout', { type: 'remove', data: {checkoutId: planId }});
        }
      }
      viewMenuPDF = () =>{
        const { checkoutPlanParams } = this.props;
        var planId =  checkoutPlanParams.id; 
        if(planId){
          this.props.spin();
          this.props.socket.emit('mobile_checkout', { type: 'viewmenupdf', data: {checkoutId: planId }});
        }
      }
      getDefaultDishes = (dietId, types) => { 
        // this.props.spin();
        this.props.socket.emit('default_dishes', { type: 'get', data: { dietId, types } });
        this.props.socket.emit('custom_plan', { type: 'get', data: { diet_id:dietId, mealTypes:types } });
      };
    onContineValid =()=>{
      const { checkoutMealPlan } = this.props;
      const today = moment().startOf('day');
      const startDate = (checkoutMealPlan)?Object.keys(checkoutMealPlan)[0]:'';
      const date = moment.unix(startDate); 
      const buffered = date.isBetween(today, today.clone().add(3, 'day'), '[)');
      if(buffered){
        browserHistory.push('/checkout/delivery-dates');
      }
    }
    updateDayMeals =(checkoutId,detailsData,deliveryName=null)=>{
        this.props.spin();
        if(checkoutId && detailsData){
          // detailsData
          this.props.socket.emit('mobile_checkout', { type: 'setMealPlan',   data: {
            checkoutId:checkoutId,
            checkoutMealPlan: detailsData,
            deliveryName:deliveryName
          }});
        }
    }
    render() {
        const { checkoutPlanParams } = this.props;
        const { priceDatas } = checkoutPlanParams;
        return <Fragment>
            <div className="main-holder checkout-process-con wrap-progress-page" >
            <Notpaidnotification />
            <div className="container checkout-process menus">
                        <div className="row">
                        <div className='col-12'>
                           <Tabs />
                           <div className="inner-content">
                            <h2 className="mobile-header">Menu</h2>
                           <Days {...this.props} skipTheDay={this.skipTheDay} updateDayMeals={this.updateDayMeals}  />
                           </div>
                           <BottomNav priceDatas={priceDatas} viewMenuPDF={this.viewMenuPDF} removeCheckout={this.removeCheckout} isShowBag={true} />
                        </div>
                    </div>
                    </div>
              </div>
        </Fragment>
    }
}
CheckoutStep4.propTypes = {
    checkoutPlanParams: PropTypes.object.isRequired,
    checkoutMealPlan: PropTypes.object.isRequired,
    setCheckoutMealPlan: PropTypes.func.isRequired,
    setCheckoutPlanParams: PropTypes.func.isRequired,
    dataArray: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    checkoutPlanParams: state.checkoutPlanParams,
    dataArray: state.dataArray,
    user:state.user,
    checkoutMealPlan: state.checkoutMealPlan,
    customPlanSides:state.customPlanSides,
    customPlans: state.customPlans
});

const bindAction = dispatch => ({
    setModal: obj => dispatch(setModal(obj)),
    setCheckoutMealPlan: plan => dispatch(setCheckoutMealPlan(plan)),
    setCheckoutPlanParams: obj => dispatch(setCheckoutPlanParams(obj)),
    spin: () => dispatch(spin()),
    stop: () => dispatch(stop()),
    updateCheckoutMealPlan: (date,obj) => dispatch(updateCheckoutMealPlan(date,obj)),
    clearModal: obj => dispatch(clearModal(obj))
});
  
export default socketConnect(connect(mapStateToProps, bindAction)(CheckoutStep4));